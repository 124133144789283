import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        userInfo: null,
        token: null,
        requestDetails: null,
        billingTerms: [],
    },
    mutations: {
        setLoginStatus(state, status) {
            state.isLoggedIn = status;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setToken(state, token) {
            state.token = token;
        },
        setRequestDetails(state, details) {
            state.requestDetails = details;
        },
        SET_BILLING_TERMS(state, terms) {
            state.billingTerms = terms;
        },
    },
    actions: {
        login({ commit }, { userInfo, token }) {
            commit('setLoginStatus', true);
            commit('setUserInfo', userInfo);
            commit('setToken', token);
        },
        logout({ commit }) {
            commit('setLoginStatus', false);
            commit('setUserInfo', null);
            commit('setToken', null);
        },
        setRequestDetails({ commit }, details) {
            commit('setRequestDetails', details);
        },
        setRequestDetails({ commit }, details) {
            commit('setRequestDetails', details);
        },
        updateBillingTerms({ commit }, terms) {
            commit('SET_BILLING_TERMS', terms);
        },
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        userInfo: state => state.userInfo,
        token: state => state.token,
        userTypeName: (state) => {
            const userTypes = {
                0: 'SystemUser',
                1: 'Client',
                2: 'Carrier',
                3: 'Broker',
                4: 'Operator',
            };
            return state.userInfo ? userTypes[state.userInfo.userType] : null; // Returns user type based on userType ID
        },
        requestDetails: (state) => state.requestDetails,
        billingTerms: (state) => state.billingTerms, 
    },
    plugins: [createPersistedState()],
});
