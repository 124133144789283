<template>
  <div class="side-bar">
    <v-list class="side-bar__list pt-6">
      <v-list-item-group color="primary" class="side-bar__list-group">
        <!-- Loop through all items -->
        <v-list-item
          v-for="(item, index) in listItems"
          :key="index"
          :class="{'active': isActive(item.title)}"
          :to="getRouteByTitle(item.title)"
          class="side-bar__list-item"
        >
          <div class="side-bar__item-content">
            <!-- Icon before the title -->
            <div class="d-flex align-center gap-15">
              <img
                v-if="item.iconBefore"
                class="side-bar__icon side-bar__icon--before"
                :src="item.iconBefore.src"
                :alt="item.iconBefore.alt"
              />
              <span class="side-bar__title">{{ item.title }}</span>
            </div>
            <!-- Icon after the title -->
            <img
              v-if="item.iconAfter"
              class="side-bar__icon side-bar__icon--after"
              :src="item.iconAfter.src"
              :alt="item.iconAfter.alt"
            />
          </div>
        </v-list-item>

        <v-divider class="my-4"></v-divider>
        <!-- Rocket image -->
        <img
          v-if="$store.getters.userInfo.userType == 1"
          @click="$router.push({ name: 'new-request' })"
          class="side-bar__rocket-icon"
          :src="rocketIcon"
          alt="New Request"
        />

        <!-- Sign-out item -->
        <v-list-item @click="logoutDialog = true" class="side-bar__list-item">
          <div class="side-bar__item-content">
            <div class="d-flex align-center gap-15">
              <img
                v-if="signOutItem.iconBefore"
                class="side-bar__icon side-bar__icon--before"
                :src="signOutItem.iconBefore.src"
                :alt="signOutItem.iconBefore.alt"
              />
              <span class="side-bar__title">{{ signOutItem.title }}</span>
            </div>
            <img
              v-if="signOutItem.iconAfter"
              class="side-bar__icon side-bar__icon--after"
              :src="signOutItem.iconAfter.src"
              :alt="signOutItem.iconAfter.alt"
            />
          </div>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-dialog
      v-model="logoutDialog"
      width="375"
      overlay-color="#D9D9D9"
      overlay-opacity="0.4"
    >
      <v-card class="popup pa-6 text-center">
        <p class="popup__label">متأكد أنك تريد تسجيل الخروج؟</p>
        <p class="popup__text">
          قد تفقد أي بيانات غير محفوظة. تأكد من حفظ عملك و طلباتك قبل المتابعة
        </p>
        <div class="popup__actions">
          <v-btn
            @click="logout()"
            color="error"
            class="popup__actions__confirm-btn"
            >تأكيـد</v-btn
          >
          <v-btn
            @click="logoutDialog = false"
            color="#F3F6F5"
            class="popup__actions__cancel-btn"
            >عـودة</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoutDialog: false,
      listItems: [
        {
          title: "لوحـة القيـادة",
          iconBefore: {
            src: require("@/assets/images/dahsboard-icon.svg"),
            alt: "Dashboard",
          },
          iconAfter: {
            src: require("@/assets/images/expand.svg"),
            alt: "Expand",
          },
        },
        {
          title: "شحناتـى",
          iconBefore: {
            src: require("@/assets/images/file-text-icon.svg"),
            alt: "Shipping",
          },
          iconAfter: {
            src: require("@/assets/images/expand.svg"),
            alt: "Expand",
          },
        },
        {
          title: "الدعـم الفنـى",
          iconBefore: {
            src: require("@/assets/images/phone-icon.svg"),
            alt: "Support",
          },
          iconAfter: {
            src: require("@/assets/images/expand.svg"),
            alt: "Expand",
          },
        },
        {
          title: "الشروط و الأحكـام",
          iconBefore: {
            src: require("@/assets/images/terms-conditions-icon.svg"),
            alt: "Terms",
          },
          iconAfter: {
            src: require("@/assets/images/expand.svg"),
            alt: "Expand",
          },
        },
      ],
      rocketIcon: require("@/assets/images/rocket.svg"),
      signOutItem: {
        title: "تسجيـل الخـروج",
        iconBefore: {
          src: require("@/assets/images/logout-icon.svg"),
          alt: "Logout",
        },
        iconAfter: {
          src: require("@/assets/images/expand.svg"),
          alt: "Expand",
        },
      },
    };
  },
  computed: {
    isShippingPath() {
      return (
        this.$route.name === "shipping-requests" ||
        this.$route.name === "new-request" ||
        this.$route.name === "request-details"
      );
    },
  },
  methods: {
    isActive(title) {
      if (title === "شحناتـى") {
        return this.isShippingPath;
      }
      return this.$route.name === this.getRouteNameByTitle(title);
    },
    getRouteNameByTitle(title) {
      switch (title) {
        case "لوحـة القيـادة":
          return "dashboard";
        case "الدعـم الفنـى":
          return "support";
        case "الشروط و الأحكـام":
          return "terms";
        default:
          return "";
      }
    },
    getRouteByTitle(title) {
      switch (title) {
        case "لوحـة القيـادة":
          return { name: "dashboard" };
        case "شحناتـى":
          return { name: "shipping-requests" };
        case "الدعـم الفنـى":
          return { name: "support" };
        case "الشروط و الأحكـام":
          return { name: "terms" };
        default:
          return null;
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  background-color: $white;
  width: 200px;
  height: 100%;
  z-index: 1;
  padding: 0px 16px;

  &__list {
    padding: 0;
  }

  &__list-group {
    margin: 0;
    .v-list-item {
      transition: all 0.2s;
      &--active,
      &:hover,
      &.active {
        background-color: $primary;
        color: $white !important;
        .side-bar__icon {
          filter: invert(1) brightness(1) contrast(100%);
        }
      }
    }
  }

  &__list-item {
    padding: 8px 0;
    min-height: 36px;
    border-radius: 12px;
    overflow: hidden;
    padding: 0px 12px;
    margin-bottom: 8px;
  }

  &__item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
  }

  &__icon {
    &--before {
    }

    &--after {
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
  }

  &__rocket-icon {
    cursor: pointer;
    margin: 16px 0;
  }
}
</style>
