<template>
  <v-app>
    <v-container fluid>
      <navbar-component />
      <div class="d-flex">
        <sidebar-component v-if="isLoggedIn" />
        <router-view></router-view>
      </div>

      <footer-component v-if="!footerHiddenView.includes($route.name)" />
    </v-container>
  </v-app>
</template>

<script>
import NavbarComponent from "@/common/components/Layout/NavbarComponent.vue";
import SidebarComponent from "@/common/components/Layout/SidebarComponent.vue";
import FooterComponent from "@/common/components/Layout/FooterComponent.vue";
export default {
  name: "App",
  components: { NavbarComponent, FooterComponent, SidebarComponent },
  data() {
    return {
      footerHiddenView: [
        "login",
        "forget-password",
        "otp",
        "form-succeed",
        "reset-password",
        "register",
        "shipping-requests",
        "new-request",
        "not-found",
        "request-details"
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>