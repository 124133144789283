"use strict";
import axios from "axios";
import store from "@/store";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // withCredentials: true,
});

// Add a response interceptor
http.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch("logout");
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);
export default http;
