<template>
  <div>
    <v-overlay
    z-index="2000"
      color="#D9D9D9"
      opacity="0.8"
      :value="showLoader"
    >
      <v-progress-circular
        indeterminate
        size="64"
        :width="5"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: {
    showLoader: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
};
</script>